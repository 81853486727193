<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex shrink>
        <v-card class="rounded-xl" elevation="12" width="350">
          <v-card-text>
            <div class="text-center text-h4 mt-4 font-weight-bold">Authentication</div>
            <v-card-text>
              <v-row class="mt-5">
						
                <v-col cols="12">
                  <v-text-field outlined dense v-model="webActivationCode" label="6 Digit Code"
                    :append-icon="showCode ? 'mdi-eye-off' : 'mdi-eye'" :type="showCode ? 'text' : 'password'"
                    v-on:keyup.enter="submit" @click:append="(showCode = !showCode)"
                    v-on:keyup.enter.prevent="submit" :hide-details="webActivationCodeErrors.length === 0"
                    :error-messages="webActivationCodeErrors" @input="$v.webActivationCode.$touch()">
                  </v-text-field>
                </v-col>
							<v-col cols="12">
								<v-flex>
									<v-checkbox hide-details label="I agree to the terms and conditions" v-model="termsAndCondtions"></v-checkbox>
								</v-flex>
							</v-col>

                <v-col cols="12">
                  <v-btn :disabled="!termsAndCondtions" :loading="loading" block v-on:click.stop="submit" color="primary">Authenticate</v-btn>
                </v-col>
                <v-col cols="12">
                  <v-btn block text @click="back">Back To Login</v-btn>
                  <!-- <v-btn :disabled="!termsAndCondtions" :loading="loading" block v-on:click.stop="submit" color="primary">Authenticate</v-btn> -->
                </v-col>
              </v-row>
            </v-card-text>

          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import authService from "../service";

const { validationMixin } = require("vuelidate");
const { required, requiredIf } = require("vuelidate/lib/validators");

export default {
  name: "authentication-web-token",
  data() {
    return {
			termsAndCondtions: false,
      loading: false,
      showCode: false,
      webActivationCode: null,
			ContactId: null
    };
  },
  components: {},
  async created() {
    // localStorage.removeItem(`${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`);
		
		let contact = await JSON.parse(localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}contact`));
		let token = await localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`);
		// console.log('contact is :', contact, token);
		if (!contact || !token) {
			this.$router.push({ path: "/login" });
			return false;
		}
		this.ContactId = contact.id;
  },
  methods: {
    back() {
      this.$router.push({ path: "/login" });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: true,
            timer: 2000,
          })
          .fire({
            type: "error",
            title: "Invalid Input",
          });
      } else {
        this.loading = true;
        this.$Progress.start();
				let authObject = {
					webActivationCode: this.webActivationCode,
					ContactId: this.ContactId
				}
        return authService
          .getWebToken(authObject)
          .then(async (response) => {
            console.log("response", response);
            this.$Progress.finish();
            // this.checkResponse(response);
            if (response && response.data && response.data.token) {
              await localStorage.setItem(
                `${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`,
                response.data.token
              );
              // this.$router.push({ path: "/login" });
							
							let token = await localStorage.getItem(`${process.env.VUE_APP_STORAGE_PREFIX}token`);
							if (!token) this.$router.push({ path: "/login" });
              this.$router.push({ path: "/" });
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            this.$Progress.finish();
            console.log('error', error);
            this.$swal("Validation", "Please enter a valid code", "warning");
          });
      }
    },
  },
  computed: {
    webActivationCodeErrors() {
      const errors = [];
      if (!this.$v.webActivationCode.$dirty) return errors;
      if (!this.$v.webActivationCode.required) errors.push("Web Activation Code is required.");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations() {
		return {
			webActivationCode: {
				required,
			},
		}
  },
};
</script>

<style scoped>

input[type="text"]
{
    background: transparent;
}
</style>


