import cellsimPortalService from '@/api/cellSim.js';

export default {
  /**
   *  Get web token from server
   * @param {} webActivationCode
   */
	 verifyWebToken(params, temp) {
    console.log('params', params)
    return cellsimPortalService
      .Api()
      .post('webActivationcode/verifyWebToken', {
        token: params.webToken,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
				// localStorage.removeItem(
				// 	`${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`,
				// );
        throw error;
      });
  },
  /**
   *  Get token from server
   * @param {} id
   * @param {*} pin
   * @param {*} locationCode
   * @param {*} deviceCode
   * @param {*} deviceName
   */
  authenticate(params, temp) {
    console.log('params', params)
    return cellsimPortalService
      .Api()
      .post('token', {
        email: params.email,
				username: params.username,
        password: params.password,
        siteAddress: params.siteAddress,
        code: params.code,
      })
      .then(response => {
        if (!temp) {
          localStorage.setItem(
            `${process.env.VUE_APP_STORAGE_PREFIX}token`,
            response.data.data.token,
          );
        }
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
  /**
   *  Get web token from server
   * @param {} webActivationCode
   */
	 getWebToken(params, temp) {
    console.log('params', params)
    return cellsimPortalService
      .Api()
      .post('webActivationcode/activate', {
        webActivationCode: params.webActivationCode,
				ContactId: params.ContactId
      })
      .then(response => {
        if (!temp) {
          localStorage.setItem(
            `${process.env.VUE_APP_STORAGE_PREFIX}webActivationToken`,
            response.data.data.token,
          );
        }
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
	signup(params, temp) {
    console.log('params', params)
    return cellsimPortalService
      .Api()
      .post('public/customer', params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw error;
      });
  },
  randomString(length, chars) {
    let result = '';
    for (let i = length; i > 0; --i) {
      result += chars[Math.floor(Math.random() * chars.length)];
    }
    return result;
  },
};
